import React, { useState } from "react";
import plus from "../../assets/plus.svg";

const FrequentlyAskQuestions = () => {
  const data = [
    {
      question: "What is Cuffi ?",
      answer:
        "Cuffi is a decentralized cryptocurrency token built on the Ethereum blockchain using the ERC-20 standard. With a total supply of 1 billion tokens, Cuffi is designed primarily for staking purposes, enabling users to lock up their tokens in return for rewards. Cuffi supports a wide range of decentralized finance (DeFi) applications, allowing users to participate in staking, governance, and future ecosystem developments.",
    },
    {
      question: "How to trade Cuffi ?",
      answer:
        "You can trade Cuffi on decentralized exchanges (DEXs) that support ERC-20 tokens, such as Uniswap, Sushiswap, or other Ethereum-based platforms.",
    },
    {
      question: "How to earn rewards on cuffi ?",
      answer:
        "You can earn rewards by staking your Cuffi tokens in designated staking pools. By locking up your tokens for a certain period, you contribute to the security and efficiency of the network, and in return, you earn staking rewards. Staking Cuffi allows you to passively earn rewards while contributing to the ecosystem.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(-1);
  const handleAccordian = (index) => {
    if (index === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };
  return (
    <>
      <div className="bg-[#F8F8F8] w-full px-4 sm:px-20 py-10  grid lg:grid-cols-[30%_70%] gap-4 xl:gap-1">
        <div className="flex flex-col mt-5">
          <h2 className="font-bold text-[20px] text-center lg:text-left lg:text-[32px] text-[#0E7570] ">
            Frequently Asked
          </h2>
          <h2 className="font-bold text-[20px] text-center lg:text-left lg:text-[32px]  text-[#0E7570]">
            Questions
          </h2>
        </div>

        <div className="mt-4 lg:mt-">
          {data?.map(({ question, answer }, index) => {
            const isopen = openIndex === index;
            return (
              <div
                key={index}
                className={`${
                  index !== data.length - 1 ? "border-b" : "border-none"
                } border-[#D3D3D3] py-6 cursor-pointer`}
              >
                <div
                  onClick={() => handleAccordian(index)}
                  className="flex justify-between"
                >
                  <p className="text-[#0A0A0A] text-[18px] leading-[21.78px] font-semibold ">
                    {question}
                  </p>
                  <div className="rounded-full border-2 border-[#0E7570] p-1">
                    <img
                      className={`${
                        isopen ? "rotate-45" : ""
                      } transition-transform duration-300`}
                      src={plus}
                      alt="plusIcon"
                    />
                  </div>
                </div>
                {isopen && (
                  <div className="mt-2 font-normal transition-all duration-400 ease-in">
                    {answer}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FrequentlyAskQuestions;
